import React from 'react';
import { Step, StepButton, Stepper } from '@material-ui/core';

export interface SignupStepperProps {
  currentStep: SignupSteps;
  changeStep:(step:SignupSteps)=>void
}

export enum SignupSteps {
  AccountDetails,
  Organisation,
}

export const SignupStepper = ({ currentStep, changeStep }:SignupStepperProps) => {
  return (
    <Stepper nonLinear alternativeLabel activeStep={currentStep}>
      <Step key={'Campaign Details'}>
        <StepButton data-testid='step-details' onClick={()=>{changeStep(SignupSteps.AccountDetails);}}>
        Account Details
        </StepButton>
      </Step>
      <Step key={'Organization'}>
        <StepButton data-testid='step-organization' disabled>
          Organisation
        </StepButton>
      </Step>
    </Stepper>
  );
};
