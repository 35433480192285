/* eslint react/prop-types: 0 */
import React, { useCallback, useState } from 'react';
import { Box, Grid, Button } from '@adludio/components';
import ArrowForward from '@material-ui/icons/ArrowForward';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useSignupForm } from './useSignupForm';
import { FormField } from '../../../Components/FormField';
import { makeStyles } from '@material-ui/core';
import { Invitation } from '../index';
import { UserInfo } from '../SignupOrchestor/Signup';
import { useMediaQuery } from '@adludio/components';
import GoogleCard from '../../../Components/Reusables/GoogleCard';
import { useSnackbar } from '@adludio/components';
import { useTheme } from '@adludio/components';

export type AccountSignupProps = {
    invitation?: Invitation;
    handleAccountStep: (user: UserInfo, google?:any) => void;
    userInfo?:UserInfo
};

const useStyles = makeStyles(() => ({
  passwordPattern: {
    paddingInlineStart: 15,
    color: '#E86464'
  }
}));


export const AccountSignup: React.FC<AccountSignupProps> = ({ invitation, handleAccountStep, userInfo }) => {
  const { nameField, jobField, passwordField, validateForm } = useSignupForm(userInfo);
  const [isFormFieldValid, setIsFormFieldValid] = useState<boolean | undefined>();
  const { passwordPattern } = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const { enqueueSnackbar } = useSnackbar();

  const signupUser = () => {
    const isFormValid = validateForm();
    setIsFormFieldValid(isFormValid);
    if (isFormValid) {
      handleAccountStep({
        name: nameField.value,
        jobTitle: jobField.value,
        password: passwordField.value,
      });
    }
  };

  const googleFail = useCallback(() => enqueueSnackbar('Sign up with google failed - please sign up manually', { variant: 'error' }), [enqueueSnackbar]);
  const googleSuccess = useCallback(
    (GoogleUser: { [key: string]: any }) =>{
      console.log(GoogleUser);
      handleAccountStep({
        name: GoogleUser.name,
        jobTitle: jobField.value,
      }, GoogleUser);
      return;
    },
    [handleAccountStep, jobField.value]
  );

  return (
    <>
      <Grid xs={10} sm={8} md={10} container item direction='column' justify='center'>
        <Grid item xs>
          <Box pb='1.75rem'>
            <FormField
              {...nameField}
              disabled={false}
              name='name'
              required
              label='Full name'
            />
          </Box>
        </Grid>
        <Grid item xs>
          <Box pb='1.75rem'>
            <FormField
              {...jobField}
              disabled={false}
              name='job'
              label='Job Title'
            />
          </Box>
        </Grid>
        <Grid item xs>
          <Box pb='1.75rem'>
            <FormField
              disabled
              required
              name='email'
              label='Email'
              value={invitation?.email}
            />
          </Box>
        </Grid>
        <Grid item xs>
          <FormField
            {...passwordField}
            disabled={false}
            name='password'
            label='Password'
            isValid={!(isFormFieldValid === false && passwordField.isValid === false)}
            password
          />{(isFormFieldValid === false && passwordField.isValid === false) ?
            <FormHelperText>
              <ul className={passwordPattern}>
                <li>Password needs to contain at least 8 characters in length
                </li>
                <li>Must contain 1 uppercase characters.</li>
                <li>Must contain 1 special character.</li>
                <li>Must contain 1 number.</li>
              </ul>
            </FormHelperText> :
            <FormHelperText>
            Password needs to contain 8 characters. Must include at least one number, one uppercase character and one special character.
            </FormHelperText>
          }
        </Grid>
        <Grid item xs>
          <Box pt='5%' pb='2rem'>
            <Button
              data-testId={'next-step'}
              variant='contained'
              size='large'
              fullWidth
              onClick={signupUser}
            >
            Next Step <ArrowForward />
            </Button>
          </Box>
        </Grid>
      </Grid>
      <GoogleCard isLarge={isDesktop} onFailure={googleFail} onSuccess={googleSuccess} />
    </>
  );
};
