import React from 'react';
import { H5, Grid, Body2, Box } from '@adludio/components';
import { Button } from '@adludio/components';


interface ForgotPwSuccess {
  onResendClick: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void);
}

const ForgotPwSuccess: React.FC<ForgotPwSuccess> = (props: ForgotPwSuccess) => {
  return (
    <Grid container justify='center' direction='column'>
      <Grid item xs container justify='center'>
        <H5>Email sent</H5>
      </Grid>
      <Grid item xs container justify='center'>
        <Box pt='2rem' alignText='center'>
          <p >A reset link has been sent to the registered email. Please follow the instructions in the email.</p>
        </Box>
      </Grid>
      <Box pt='2rem'>
        <Grid item xs container justify='center' spacing={2} direction='column'>
          <Body2>No email after 10 minutes?</Body2>
          <Box>
            <Button variant='contained' onClick={props.onResendClick}>Resend email</Button>
          </Box>
        </Grid>
      </Box>

    </Grid>
  );
};

export default ForgotPwSuccess;

