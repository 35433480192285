import React from 'react';

import { Grid, Link, Box, H5 } from '@adludio/components';

import { getURLQueryParams } from '../../util/redirect';
import { Link as ReachLink } from '@reach/router';

const emoji = require('../../static/adludio-emoji-robot.png');

const NewPwSuccess: React.FC = () => {
  return (
    <Grid item container direction='column' xs>
      <Grid item container direction='column' justify='space-evenly' xs>
        <Grid item xs>
          <Box pt='3rem'>
            <H5>Your password has now been reset!</H5>
          </Box>
        </Grid>
        <Grid item xs>
          <Box pt='5rem'>
            <p>
          You can now <br />
              <Link component={ReachLink} to={`/?payload=${getURLQueryParams().encoded.payload}`}>
             Log in
              </Link>
            </p>
          </Box>
          <img src={emoji} height={30} alt='Robot emoji' />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NewPwSuccess;
