import { getThumbnailSignedURL, uploadImageToBucket } from '../thumbnailFunctions';
import { useCallback, useState } from 'react';

/*
 * uplaod a image directly to de s3 bucket using a pre signed s3 url
 */
export const useUploadThumbnailImage = () => {
  const [state, setState] = useState<any>({
    error: null,
    isLoading: false
  });

  const uploadImage = useCallback(async (fileName:string, fileData: any, companyName: string, token: string, email:string) => {
    setState({ error: null, isLoading: true });
    const { error, data } = await getThumbnailSignedURL({ fileName, companyName, token, email });
    if (error) {
      setState({ isLoading: false, error: error, });
      return { error, data };
    }
    const signedUrl: string = data.signedUrl;
    const success = await uploadImageToBucket(signedUrl, fileData);
    if (!success) {
      setState({ isLoading: false, error: 'Unknown error', });
      return { error, data };
    }

    // success
    setState({ isLoading: false, error: null });
    let thumbnailURL = signedUrl.split('?')[0];
    return { error, thumbnailURL };
  }, []);


  return { state, uploadImage };
};
