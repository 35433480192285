/* eslint-disable complexity */
import React, { useLayoutEffect, useMemo } from 'react';
import { Box, Body2, Button, CheckboxButton, Dropdown, Dropzone, Grid, Typography } from '@adludio/components';
import { Caption } from '@adludio/components';
import { FilePreview } from '../../../Components/FilePreview';
import { FormField } from '../../../Components/FormField';
import { Invitation } from '../index';
import { industryList } from '../industryList';
import { makeStyles, TextField } from '@material-ui/core';
import { OrganisationFormInitialState, useOrganizationForm } from './useOrganizationForm';
import { UserInfo } from '../SignupOrchestor';
import { useRegisterUser } from '../useRegisterUser';
import { useSnackbar } from '@adludio/components';
import { Body1, Autocomplete } from '@adludio/components';
import { AutocompleteGetTagProps, AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete';
import countryList from 'react-select-country-list';
import { Chip } from '@adludio/components';
import { AdludioTheme } from '@adludio/components/dist/Theme/types';

export type OrganisationDetails = OrganisationFormInitialState & {
  legal: boolean;
  hotjar: boolean
  companyLogo: File | null;
};

export type OrganisationSignupProps = {
  userInfo: UserInfo;
  googleUser?: any;
  isDesktop: boolean;
  invitation: Invitation;
  onSuccessCallback: (accessToken: string) => void;
  storeOrganizationDetails: (datails: OrganisationDetails) => void;
  storedOrganizationDetails?: OrganisationDetails;
};

const useStyles = makeStyles((theme: AdludioTheme) => ({
  dropdown: {
    textAlign: 'left',
  },
  disabledClass: {
    color: 'rgba(0, 0, 0, 0.38)',
    pointerEvents: 'none',
  },
  links: {
    // shit error becasuse wrong module declaration color: theme.palette.primary.dark,
    cursor: 'pointer',
  },
  addressText: {
    textAlign: 'left',
    color: theme.palette.text.secondary
  },
  hyperlinks: {
    color: '#a100ff'
  },
}));

export const OrganisationSignup: React.FC<OrganisationSignupProps> = ({
  googleUser,
  userInfo,
  isDesktop,
  invitation,
  onSuccessCallback,
  storeOrganizationDetails,
  storedOrganizationDetails,
}) => {
  const imgStyle: React.CSSProperties = { borderRadius: '10px', width: '100%', height: '100%', objectFit: 'cover' };
  const {
    companyNameField,
    setCompanyLogo,
    industryField,
    companyLogo,
    legalField,
    hotjarField,
    countryField,
    addressField,
    emailContactField,
    isFormValid,
  } = useOrganizationForm({
    companyName: invitation.companyName,
    industry: invitation.industry,
    address: invitation.address,
    country: invitation.country,
    contactEmail: invitation.contactEmail
  }, storedOrganizationDetails);
  const {
    handleSubmit,
    registerUserState: { isLoading },
  } = useRegisterUser(userInfo, invitation, googleUser);


  const { dropdown, disabledClass, addressText, hyperlinks } = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const countries = useMemo(() => countryList().getData(), []);

  const signup = () => {
    handleSubmit(companyNameField.value, companyLogo!, industryField.value, countryField.value, addressField.value, emailContactField.value)
      .then((result) => {
        if (result.error) {
          enqueueSnackbar(`Error occurred: ${result.error.message}`, { variant: 'error', persistent: true });
          return;
        }
        onSuccessCallback(result.data.accessToken);
      })
      .catch(console.error);
  };
  useLayoutEffect(() => {
    return () =>
      storeOrganizationDetails({
        companyName: companyNameField.value,
        industry: industryField.value,
        legal: legalField.value || false,
        hotjar: hotjarField.value || false,
        address: addressField.value,
        contactEmail: emailContactField.value,
        country: countryField.value,
        companyLogo: companyLogo,
      });
  });

  return (
    <Grid xs={10} sm={8} md={10} container item direction='column' justify='center'>
      <Grid item xs>
        <Box pb='1.75rem'>
          <FormField
            {...companyNameField}
            disabled={isLoading || typeof invitation.companyName !== 'undefined'}
            name='companyName'
            required
            label='Organisation name'
          />
        </Box>
      </Grid>
      <Grid item xs>
        <Box pb='1.75rem'>
          <Dropdown
            data-testId={'industry'}
            className={dropdown}
            disabled={isLoading || invitation.industry}
            {...industryField}
            label='Business Type'
            menuItems={industryList.map((i) => i.value)}
            variant='outlined'
            required
            // eslint-disable-next-line no-undefined
            inputProps={{ InputLabelProps: { shrink: undefined }, notched: undefined }}
          />
        </Box>
      </Grid>
      <Grid container justify='flex-start' item>
        <Box pb='0.4rem'>
          <Body1>Company Address*</Body1>
        </Box>
      </Grid>
      <Grid container justify='flex-start' item>
        <Box pb='1.75rem'>
          <Caption className={addressText}>Please input the company address, this will be used for invoicing purposes</Caption>
        </Box>
      </Grid>
      <Grid item xs>
        <Box pb='1.75rem'>
          <Autocomplete
            options={countries.map((option) => option.label)}
            {...countryField}
            autocompleteValue={countryField.value}

            onChange={(event: React.ChangeEvent<HTMLInputElement>, newValue: string) => {
              countryField.updateValue(newValue);
            }}
            id='countryField'
            freeSolo
            renderTags={(value: string[], getTagProps: AutocompleteGetTagProps) =>
              value.map((option: string, index: number) => <Chip key={option} label={option} {...getTagProps({ index })} />)
            }
            renderInput={(params: AutocompleteRenderInputParams) => (
              <TextField
                onInput={(event:React.ChangeEvent<HTMLInputElement>)=>{
                  const value = event.target.value;
                  const existCountry = countries.find((c: { label:string })=> c.label.trim().toLowerCase() === value.trim().toLowerCase());
                  if (existCountry){
                    countryField.updateValue(existCountry.label);
                  }
                }}
                {...params}
                name='countryField'
                required
                variant='outlined'
                label='Country'
                placeholder='Search'
                inputProps={{
                  ...params.inputProps,
                }}
                disabled={isLoading || !!invitation.country}
              />
            )}
            disabled={isLoading || !!invitation.country}
          />
        </Box>
      </Grid>
      <Grid item>
        <Box pb='1.5rem '>
          <FormField
            {...addressField}
            multiline
            name='addressField'
            label='Address'
            disabled={isLoading || !!invitation.address}
            required
          />
        </Box>
      </Grid>
      <Grid container justify='flex-start' item>
        <Box pb='0.4rem'>
          <Body1>Finance Contact Email*</Body1>
        </Box>
      </Grid>
      <Grid container justify='flex-start' item>
        <Box pb='1.75rem'>
          <Caption className={addressText}>
            Please provide the best email within the finance department for communications regarding invoicing
          </Caption>
        </Box>
      </Grid>
      <FormField
        name='emailContact'
        required
        label='Contact email'
        {...emailContactField}
        disabled={isLoading || !!invitation.contactEmail}
      />

      <Box pt='1rem'>
        <Grid container item justify='flex-start'>
          <Typography className={invitation.companyName ? disabledClass : ''}>Logo</Typography>
        </Grid>
        <Grid className={invitation.companyName ? disabledClass : ''} item>
          {!invitation.companyName ? (
            <>
              <Dropzone
                numberOfFiles='single'
                file={companyLogo}
                setFile={setCompanyLogo}
                accept='image/*'
                targetLabelString='Image'
                height='8rem'
              />
              <Box pt='0.5rem'>
                <Body2 align='left'>Please only upload .jpg or .png image formats</Body2>
              </Box>
            </>
          ) : (
            <img src={invitation.logoUrl} alt='logo' style={imgStyle} />
          )}
          {companyLogo && <FilePreview file={companyLogo} setFile={setCompanyLogo} />}
        </Grid>
      </Box>
      <Grid container justify='center'>
        <Box pt='8%'>
          <Grid item xs={12} direction='row' container>
            <Grid item xs={1}>
              <CheckboxButton
                onClick={(ev: any) => legalField.updateValue(ev.target.checked)}
                data-testId={'legal'}
                checked={legalField.value}
                name='legal'
              />
            </Grid>
            <Grid item xs={11}>
              <Box pt='0.5rem'>
                <Caption align='left'>
                  By checking this box and signing up, you confirm that you&apos;ve read and accepted our&nbsp;
                  <a href='https://www.adludio.com/termsandconditions' rel='noopener noreferrer' target='_blank' className={hyperlinks}>
                    Terms of Service & Privacy Policy
                  </a>
                  .
                </Caption>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box pt='8%'>
          <Grid item xs={12} direction='row' container>
            <Grid item xs={1}>
              <CheckboxButton
                onClick={(ev: any) => hotjarField.updateValue(ev.target.checked)}
                data-testId={'legal'}
                checked={hotjarField.value}
                name='legal'
              />
            </Grid>
            <Grid item xs={11}>
              <Box pt='0.5rem'>
                <Caption align='left'>
                By checking this box, you agree to the use of <a href='https://help.hotjar.com/hc/en-us/articles/6952777582999-Cookies-Set-by-the-Hotjar-Tracking-Code' rel='noopener noreferrer' target='_blank' className={hyperlinks}>Hotjar analytics cookies</a> for the purpose of improving user experience.
                </Caption>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Grid item xs>
          <Box pt='5%' pb={isDesktop && '12.5%'}>
            <Button
              fullWidth
              disabled={!isFormValid() || isLoading}
              isLoading={isLoading}
              variant='contained'
              size='large'
              data-testId={'submit-organization'}
              // eslint-disable-next-line @typescript-eslint/await-thenable
              onClick={signup}
            >
              {isLoading ? 'Loading...' : 'Sign me up'}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};
