import * as emojis from '../../../static/emoji';

export type InvitationErrors = {
  [key: string]: {
    header: string;
    body: string;
    emoji: keyof typeof emojis;
  };
};

const invitationErrors: InvitationErrors = {
  INV_EXPIRED: {
    header: 'This link has expired.',
    body: 'Please contact the team member who invited you.',
    emoji: 'robot'
  },
  INV_NOT_FOUND: {
    header: 'This link seems to be broken...',
    body:
      'Please try again. Contact the team member who invited you if the problem persists.',
    emoji: 'injured'
  },
};

export default invitationErrors;
