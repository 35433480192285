import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import ConfigContext from './store';
import { ThemeProvider, SnackbarProvider } from '@adludio/components';
import { makeStyles } from '@material-ui/styles';
import { GoogleOAuthProvider } from '@react-oauth/google';

/*
 * Figuring out the correct path to our other services
 * by looking at current node env, basically:
 *
 * If env is:
 * production (live)     => sso.adludio.com
 * development (local)   => dev.sso.adludio.com
 * testing (alpha)       => testing.sso.adludio.com
 * staging (beta)        => staging.sso.adludio.com
 *
 */

const env = process.env.REACT_APP_BUILD_ENV ?? 'development';
const envPrefix = (env === 'development' ? 'dev.' : env === 'production' ? '' : env + '.');

export interface SSOConfig {
  env: string,
  ssoBEURL: string,
  portalFEURL: string,
  envPrefix: string,
}

export const config: SSOConfig = {
  env,
  ssoBEURL: `//${envPrefix}api.sso.adludio.com`,
  portalFEURL: `//${envPrefix}portal.adludio.com`,
  envPrefix: envPrefix,
};

const globalStyles = makeStyles({
  '@global': {
    body: {
      fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif`,
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      margin: 0
    }
  }
});

function SSOThemeProvider({ children }: React.PropsWithChildren<{}>) {
  globalStyles();
  return <ThemeProvider>{children}</ThemeProvider>;
}
const clientId: string = process.env.REACT_APP_GOOGLE_LOGIN_API as string;

ReactDOM.hydrate((
  <GoogleOAuthProvider clientId={clientId}>

    <SSOThemeProvider>
      <SnackbarProvider>
        <ConfigContext.Provider value={{ config }}>
          <App />
        </ConfigContext.Provider>
      </SnackbarProvider>
    </SSOThemeProvider>
  </GoogleOAuthProvider>

), document.getElementById('root'));
