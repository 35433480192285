/* eslint-disable complexity */
import { Box, Grid, H5, Typography, makeStyles, useMediaQuery, useTheme } from '@adludio/components';
/* eslint react/prop-types: 0 */
import React, { useCallback, useRef, useState } from 'react';
import { SignupStepper, SignupSteps } from './SignupStepper';

import { AccountSignup } from '../AccountSignupForm/AccountSignup';
import { AdludioLogo } from '../../../Components/Reusables';
import { AdludioTheme } from '@adludio/components/dist/Theme/types';
import { Invitation } from '../index';
import MainCard from '../../../Components/Reusables/MainCard';
import { OrganisationDetails, OrganisationSignup } from '../OrganisationSignup/OrganisationSignup';
import { navigate } from '@reach/router';
import circleBG from '../../../static/Group-428.svg';
import triangleBG from '../../../static/Group-427.svg';

type SignupProps = {
  invitation: Invitation;
  successURL: string;
};

const useStyles = makeStyles((theme: AdludioTheme) => ({
  root: {
    color: theme.palette.grey,
  },
  background: {
    minWidth: '100vw',
    minHeight: '100vh',
    backgroundColor: theme.palette.background.default,
    backgroundImage: `url(${circleBG}), url(${triangleBG})`,
    backgroundPosition: '25% 30%, 80% 100%',
    backgroundSize: '25%, 35%',
    backgroundRepeat: 'no-repeat',
    flexWrap: 'wrap',
  },
}));

export type UserInfo = {
  name: string;
  jobTitle: string;
  password?: string;
};

export const Signup: React.FC<SignupProps> = ({ invitation, successURL }) => {
  const [step, setStep] = useState<SignupSteps>(SignupSteps.AccountDetails);
  const [googleUser, setGoogleUser] = useState<{ [key: string]: any }>();
  const organizationDetails = useRef<OrganisationDetails>();
  const [userInfo, setUserInfo] = useState<UserInfo>();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const handleAccountStep = (user: UserInfo, google?: { [key: string]: any }) => {
    setUserInfo(user);
    if (google) {
      setGoogleUser(google);
    }
    setStep(SignupSteps.Organisation);
  };
  const handleStore = useCallback((details: OrganisationDetails) => {
    organizationDetails.current = details;
  }, []);

  return (
    <Box py={isDesktop && '5%'} className={useStyles().background}>
      <Grid container direction='column' justify='center' alignItems='center' className={useStyles().background}>
        <Grid item >
          <Box my='2rem'>
            <AdludioLogo />
          </Box>
        </Grid>
        <MainCard>
          <Grid container direction='column' justify='center' alignItems='center'>
            <Grid item container direction='column' justify='center'>
              <Grid item>
                <Box pb='10%'>
                  <H5>Welcome to Adludio</H5>
                  <Typography className={useStyles().root}>Create an account to get started</Typography>
                </Box>
              </Grid>
              <Grid item>
                <SignupStepper currentStep={step} changeStep={(stepper) => setStep(stepper)} />
              </Grid>
            </Grid>
            {step === SignupSteps.AccountDetails ? (
              <AccountSignup userInfo={userInfo} handleAccountStep={handleAccountStep} invitation={invitation} />
            ) : (
              <OrganisationSignup
                userInfo={userInfo!}
                googleUser={googleUser}
                storeOrganizationDetails={handleStore}
                storedOrganizationDetails={organizationDetails.current}
                onSuccessCallback={(accessToken) => {
                  navigate(`${successURL}?token=${accessToken}`).then(console.log).catch(console.error);
                }}
                isDesktop={isDesktop}
                invitation={invitation}
              />
            )}
          </Grid>
        </MainCard>
      </Grid>
    </Box>
  );
};
