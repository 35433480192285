import React from 'react';

import Login from './Pages/Login';
import Signup from './Pages/Signup';
import ForgotPassword from './Pages/ForgotPassword';
import NewPassword from './Pages/NewPassword';
import { Grid } from '@adludio/components';
import { AuthProvider } from '@adludio/react-sso';
import { Router } from '@reach/router';
import { makeStyles } from '@material-ui/core';
import adludioArrow from './static/adludio_arrow.svg';
import { AdludioTheme } from '@adludio/components/dist/Theme/types';



const appStyles = makeStyles<AdludioTheme>((theme) => ({
  root: {
    minWidth: '100vw',
    minHeight: '100vh',
    backgroundSize: '',
    backgroundColor: theme.palette.background.default,
    boxSizing: 'border-box',
    overflow: 'hidden'
  },
  arrow: {
    position: 'absolute',
    bottom: '0',
    left: '0',
    transform: 'rotate(45deg)',
    width: '10rem',
    maxWidth: '20%'
  }
}
));

// Simplified because only one env
const env = process.env.REACT_APP_BUILD_ENV ?? 'development';
const envPrefix = (env === 'development' ? 'dev' : 'testing');



function App() {

  // The following hooks are temporary until we make login a functional component

  return (
    <AuthProvider ssoBEURL={`//${envPrefix}.api.sso.adludio.com`}>
      <Grid container alignItems='center' justify='center' className={appStyles().root}>
        <Router>
          <Login default path='/login' />
          <Signup path='/signup/:invitationToken' />
          <ForgotPassword path='/forgot-password' />
          <NewPassword path='/new-password' />
        </Router>

      </Grid>
      <div style={{ position: 'relative' }}>
        <img src={adludioArrow} className={appStyles().arrow} alt='arrow'/>
      </div>
    </AuthProvider>
  );
}

export default App;
