import { useEffect, useState } from 'react';

export type AdludioError = { status: number, message: string, label: string }
export type AdludioResponse<D> = { data: D, error: null } | { data: null, error: AdludioError}

export default function useSSOGet<D>(url: string, endpoint: string){
  const [data, setData] = useState<AdludioResponse<D>>();
  useEffect(() => {
    fetch(`${url}/${endpoint}`, {
      method: 'GET',
      headers: { 'content-type': 'application/json' }
    })
      .then(res => res.json())
      .then(adludioResponse => setData(adludioResponse))
      .catch(console.log);
  }, [url, endpoint]);

  return data;

}
