import { AdludioError } from '../util/hooks/useSSOGet';

const env = process.env.REACT_APP_BUILD_ENV ?? 'development';
const envPrefix = env === 'development' ? 'dev.' : env === 'production' ? '' : env + '.';
const apiSSOUrl = `//${envPrefix}api.sso.adludio.com`;


interface Company{
  id: number;
  name: string;
  industry: string;
  logoUrl?: string;
  createdAt?: Date;
  modifiedAt?: Date;
}

 interface Result<T> {
  data: T ;
  error: AdludioError | null
}

interface NewCompanyResponse{
  message: string;
  company:Company;
}


export function resetPassword(password: string, hash: string, payload: string) {
  return fetch(`${apiSSOUrl}/auth/password-reset/end`, {
    method: 'POST',
    body: JSON.stringify({ password, hash, payload }),
    headers: {
      'content-type': 'application/json',
    },
  });
}

export function login(email: string, password: string) {
  return fetch(`${apiSSOUrl}/auth/login`, {
    method: 'POST',
    body: JSON.stringify({ email, password }),
    headers: {
      'content-type': 'application/json',
    },
  });
}


export function registerUser(data:{
  token:string, name:string, jobTitle:string, roleId:number, campaignId:string
  email?:string, password?:string, googleAccessToken?:string, companyId:number|null
  organizationName?:string, industry?:string, logoUrl?:string, address?: string, country?:string, contactEmail:string}
)
{
  return fetch(`${apiSSOUrl}/auth/register`,
    {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'content-type': 'application/json',
      }
    }).then((res) => {
    const result = res.json();
    return result;
  });
}

export function forgotPassword(email: string, payload: string) {
  return fetch(`${apiSSOUrl}/auth/password-reset/begin`, {
    method: 'POST',
    body: JSON.stringify({ email, payload }),
    headers: {
      'content-type': 'application/json',
    },
  });
}
