import { FIELD_VALIDATORS, useFormField } from '../../../Components/FormField';
import { UserInfo } from '../SignupOrchestor';

/**
 * Password must be >= 8 characters and contains 1 Uppercase letter, 1 number and 1 special character (@$!%*#?&)
 * Examples: sZZab84#, 7SS*Ss8783434A*, #123456AbC!
 */
const passwordRegexp : RegExp = new RegExp( /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/);

export function useSignupForm(initialValues?:UserInfo) {
  const nameField = useFormField( initialValues?.name || '', [FIELD_VALIDATORS.isRequired]);
  const jobField = useFormField( initialValues?.jobTitle || '', []);
  const passwordField = useFormField( initialValues?.password || '', [FIELD_VALIDATORS.withRegExp(passwordRegexp)]);

  const validateForm = () => {
    nameField.triggerValidation(nameField.value);
    jobField.triggerValidation(jobField.value);
    passwordField.triggerValidation(passwordField.value);
    return nameField.isValid && passwordField.isValid;
  };

  return {
    nameField,
    jobField,
    passwordField,
    validateForm
  };
}
