import queryString, { ParsedQuery } from 'query-string';

const env = process.env.REACT_APP_BUILD_ENV ?? 'development';
const envPrefix =
  env === 'development' ? 'dev.' : env === 'production' ? '' : env + '.';
const portalURL = `//${envPrefix}portal.adludio.com`;


export type Payload = {
    url?: string;
    params: string;
  } | null

export type URLQueryParams = {
   raw: string,
   encoded: ParsedQuery<string>
}

export const getURLQueryParams = (): URLQueryParams => {
  const raw = window.location.search.replace(/\?/g, '');

  const encoded: ParsedQuery<string> = queryString.parse(raw);

  return { raw, encoded };
};

const decodePayload = (encodedParams: string) => {
  return window.atob(encodedParams);
};

const parsePayload = (decodedPayload: string) => {
  return JSON.parse(decodedPayload);
};

const getPayload = (encodedParams: ParsedQuery<string>) => {
  return encodedParams.payload ? parsePayload(decodePayload(encodedParams.payload as string)) : '';
};

const getRedirectUrl = (token: string, encodedPayload: string, decodedPayload: Payload ) => {
  const url = decodedPayload?.url ? decodedPayload.url : portalURL;
  const existingQuery = queryString.parseUrl(url).query;
  return queryString.stringifyUrl({ url, query: { ...existingQuery, token, payload: encodedPayload } });
};

const redirect = (token: string, encodedPayload: string, decodedPayload: Payload ) => {
  const url = getRedirectUrl(token, encodedPayload, decodedPayload);
  window.location.replace(url);
};

const handleRedirect = (accessToken: string) => {
  const { encoded } = getURLQueryParams();

  const decodedPayload = getPayload(encoded);
  const encodedPayload = encoded.payload as string;
  redirect( accessToken, encodedPayload, decodedPayload);
};

export default handleRedirect;
