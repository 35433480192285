export interface ValidationResult {
    isValid: boolean;
    message: string
}

export type ValidationFunc<T> = (value: T) => ValidationResult;

const isRequired: ValidationFunc<any> = (value: any): ValidationResult => {
  let validationResult = { isValid: false, message: '' };
  const message = 'Field is required.';

  if (Array.isArray(value)) {
    validationResult.isValid = (value as Array<any>).length > 0;
  } else if (typeof value === 'string') {
    validationResult.isValid = (value as String).trim() !== '';
  } else {
    validationResult.isValid = (typeof value !== 'undefined' && value !== null);
  }

  validationResult.message = validationResult.isValid ? '' : message;
  return validationResult;
};

const withRegExp = (regExp: RegExp): ValidationFunc<any> => {
  return (value: string) => {
    const validationResult = { isValid: false, message: '' };
    validationResult.isValid = regExp.test(value);
    return validationResult;
  };
};

export const FIELD_VALIDATORS: any = {
  isRequired, withRegExp
};
