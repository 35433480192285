import React from 'react';
import { Body2, Grid, Button, Box, useSnackbar, TextInput, useMediaQuery, useTheme } from '@adludio/components';
import { forgotPassword } from '../../data';
import { ForgotPwSuccess } from '../../Components/Cards';
import isEmailValid from '../../validator/isEmailValid';
import { getURLQueryParams } from '../../util/redirect';
import { RouteComponentProps } from '@reach/router';
import MainCard from '../../Components/Reusables/MainCard';
import { AdludioLogo } from '../../Components/Reusables';
import { Typography } from '@material-ui/core';


const ForgotPassword = ({ path }: RouteComponentProps<{ path: string }>) => {

  interface Error {
    status?: number,
    message?: string,
    label?: string
  }
  interface StateProps {
    dataIsValid: boolean,
    emailIsValid: boolean,
    name: string,
    email: string,
    error: Error | null
  }
  const initialState: StateProps = {
    dataIsValid: false,
    name: '',
    emailIsValid: true,
    email: '',
    error: {
    },
  };
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const [state, setState] = React.useState(initialState);
  const { enqueueSnackbar } = useSnackbar();


  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setState({ ...state, [e.target.name]: e.target.value.trim() });
  }

  async function handleSubmit(e: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    e.preventDefault();
    if (!isEmailValid(state.email)) {
      setState({ ...state, error: { message: ' Please use a valid email address' }, emailIsValid: false });
    } else {
      setState({ ...state, emailIsValid: true, error: { message: '' } });
      const forgotPasswordResponse = await forgotPassword(state.email, getURLQueryParams().raw);
      const forgotPasswordData = await forgotPasswordResponse.json();
      const serverError = forgotPasswordData.error;
      if (serverError) {
        enqueueSnackbar('Server error', { variant: 'error' });
        setState({ ...state, error: { status: serverError ? forgotPasswordData.error.status : '' } });
      } else {
        setState({ ...state, dataIsValid: true });
      }
    }
  }


  return (
    !state.dataIsValid ?
      (
        <Box py={isDesktop && '5%'}>
          <Grid container direction='column' justify='center' alignItems='center'>
            <MainCard secondary >
              <Grid container direction='column' justify='center' alignItems='center'>
                <Grid item container direction='column' justify='center'>
                  <Grid item>
                    <Box pt='10%'>
                      <AdludioLogo />
                    </Box>
                  </Grid>
                  <Box pb='5%' pt='15%'>
                    <Typography variant={'h5'}>Reset Password</Typography>
                  </Box>
                  <Grid >
                    <Box pb='15%' px='5%'>
                      <Body2 className='supportingText forgotPwPage'>{'Please enter your registered email address to get a password reset link.'}</Body2>
                    </Box>
                  </Grid>

                </Grid>
                <Grid
                  xs={10}
                  sm={8}
                  md={10}
                  container
                  item
                  direction='column'
                  justify='center'
                >
                  <Grid item xs>
                    <Box pb='10%'>
                      <TextInput
                        onChange={handleChange} error={!state.emailIsValid}
                        helperText={!state.emailIsValid ? 'Please enter a valid email address' : ''}
                        label='Email' variant='outlined' value={state.email}
                        required name='email' type='email' id='email'
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Grid >
                  <Box pt='20%' pb='50%'>
                    <Button fullWidth variant='contained' size='large' onClick={handleSubmit} disabled={!state.emailIsValid}>
                      Reset password
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </MainCard>
          </Grid>
        </Box>
      ) :

      (<ForgotPwSuccess onResendClick={handleSubmit} />)

  );
};


export default ForgotPassword;
