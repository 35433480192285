import React from 'react';
import { Grid, TextInput } from '@adludio/components';


export interface FormFieldProps {
    label: string;
    value: any;
    onChange?: (e: React.ChangeEvent<any>) => void;
    required?: boolean;
    name: string;
    multiline?: boolean;
    message?: string;
    isValid?: boolean;
    disabled?: boolean;
    password?: boolean;

}

export const FormField: React.FC<FormFieldProps> = (properties) => {
  const { value, onChange, label, required, name, multiline, message, isValid, disabled, password } = properties;

  const haveError = () => {
    return typeof isValid !== 'undefined' ? !isValid : false;
  };

  return (
    <Grid item xs={12}>
      <TextInput
        disabled={disabled}
        value={value}
        onChange={onChange}
        label={label}
        required={required}
        variant='outlined'
        name={name}
        data-testid={name}
        multiline={multiline}
        rows={4}
        password={password}
        helperText={isValid ? null : message}
        error={haveError()}
      />
    </Grid>);
};
