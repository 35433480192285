interface Industry {
  label:string,
  value:string,
  key:string
}

export const industryList:Industry[] = [
  { label: 'Arts', value: 'Arts', key: 'Arts' },
  { label: 'Automotive', value: 'Automotive', key: 'Automotive' },
  { label: 'Beauty', value: 'Beauty', key: 'Beauty' },
  { label: 'Books', value: 'Books', key: 'Books' },
  { label: 'Careers', value: 'Careers', key: 'Careers' },
  { label: 'Charity', value: 'Charity', key: 'Charity' },
  { label: 'Computer Software', value: 'Computer Software', key: 'Computer Software' },
  { label: 'Entertainment', value: 'Entertainment', key: 'Entertainment' },
  { label: 'Farming', value: 'Farming', key: 'Farming' },
  { label: 'Fashion/Retail', value: 'Fashion/Retail', key: 'Fashion/Retail' },
  { label: 'Finance', value: 'Finance', key: 'Finance' },
  { label: 'FMCG', value: 'FMCG', key: 'FMCG' },
  { label: 'Food & Beverage', value: 'Food & Beverage', key: 'Food & Beverage' },
  { label: 'Government & Law', value: 'Government & Law', key: 'Government & Law' },
  { label: 'Health, Wellness & Fitness', value: 'Health, Wellness & Fitness', key: 'Health, Wellness & Fitness' },
  { label: 'Insurance ', value: 'Insurance ', key: 'Insurance ' },
  { label: 'Leisure, Travel & Tourism', value: 'Leisure, Travel & Tourism', key: 'Leisure, Travel & Tourism' },
  { label: 'Lux Watches & Jewellery', value: 'Lux Watches & Jewellery', key: 'Lux Watches & Jewellery' },
  { label: 'Marketing And Advertising', value: 'Marketing And Advertising', key: 'Marketing And Advertising' },
  { label: 'News', value: 'News', key: 'News' },
  { label: 'Pets', value: 'Pets', key: 'Pets' },
  { label: 'Property', value: 'Property', key: 'Property' },
  { label: 'Religion', value: 'Religion', key: 'Religion' },
  { label: 'Science', value: 'Science', key: 'Science' },
  { label: 'Society & Social Issues', value: 'Society & Social Issues', key: 'Society & Social Issues' },
  { label: 'Sports', value: 'Sports', key: 'Sports' },
  { label: 'Technology B2B', value: 'Technology B2B', key: 'Technology B2B' },
  { label: 'Technology B2C', value: 'Technology B2C', key: 'Technology B2C' },
  { label: 'Travel', value: 'Travel', key: 'Travel' },
  { label: 'Other', value: 'Other', key: 'Other' },
];
