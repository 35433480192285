
/**
 * Password must be >= 8 characters and contains 1 Uppercase letter, 1 number and 1 special character (@$!%*#?&)
 * Examples: sZZab84#, 7SS*Ss8783434A*, #123456AbC!
 */
const passwordRegexp : RegExp = new RegExp( /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/);


export default function isPasswordValid(password: string) : boolean {
  // checks if password is valid against the criteria
  return passwordRegexp.test(password);
}
