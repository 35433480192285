import React from 'react';

import MainCard from './MainCard';
import Separator from './NewSeparator';
import {
  Grid,
  Box
} from '@adludio/components';
import { Body2 } from '@adludio/components';
import { Link } from '@adludio/components';
import { makeStyles } from '@adludio/components';
import { AdludioTheme } from '@adludio/components/dist/Theme/types';
import { Link as ReachLink } from '@reach/router';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import jwtDecode from 'jwt-decode';

type GoogleCardProps = {
  isLarge?: boolean;
  isLogin?: boolean;
  onSuccess: (result: { email: string, accessToken: string }) => void;
  onFailure: () => void;
}

const useStyles = makeStyles((theme: AdludioTheme) => ({
  accountText: {
    color: theme.palette.grey
  },
  googleButton: {
    width: '100%',
    '& span': {
      margin: '0 auto',
      textIndent: -36
    }
  }
}));

const Wrapper = ({ children, isLarge }: React.PropsWithChildren<{isLarge?: boolean}>) => {
  if (!isLarge)
    return (
      <>
        <Box pt='5%' />
        <Separator />
        {children}
      </>
    );
  else
    return (
      <Grid container direction='column' justify='center' alignItems='center'>
        <MainCard secondary>{children}</MainCard>
      </Grid>
    );
};

const GoogleCard = ({ isLarge, isLogin, onSuccess, onFailure }: GoogleCardProps) => {
  const { accountText } = useStyles();
  return (
    <Wrapper isLarge={isLarge}>
      <Grid container direction='column' justify='center' alignItems='center'>
        <Grid
          xs={10}
          sm={8}
          md={10}
          container
          item
          direction='column'
          justify='center'
        >
          <Grid item>
            {isLarge && (
              <>
                <Box pt='5%' />
                <Separator />
                <Box pb='5%' />
              </>
            )}
          </Grid>
          <Grid item xs>
            <Grid container direction='column' justify='center' alignItems='center'>
              <div style={{ width: '57%', paddingTop: '6%' }}>
                <GoogleLogin
                  logo_alignment={'center'}
                  onSuccess={(credentialResponse: CredentialResponse) => {
                    if (credentialResponse.credential) {
                      const user: { email: string } = jwtDecode(credentialResponse.credential);
                      onSuccess({
                        ...user,
                        email: user.email,
                        accessToken: credentialResponse.credential,
                      });
                    } else {
                      onFailure();
                    }
                  }}
                  onError={onFailure}
                />
              </div>
            </Grid>
          </Grid>
          <Grid item xs>
            <Box pt='2rem' pb='1.5rem'>
              { !isLogin &&
                <Body2 className={accountText}>
              Already have an account? <Link component={ReachLink} to={'/'} >Log in</Link>
                </Body2>
              }
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default GoogleCard;
