import { useState } from 'react';
import { Invitation } from '.';
import { registerUser } from '../../data';
import { useUploadThumbnailImage } from '../../util/hooks/useUploadThumbnailImage';
import { UserInfo } from './SignupOrchestor';

export interface RegisterUserState {
    data: any,
    error: any,
    isLoading: boolean
}

export function useRegisterUser(userInfo:UserInfo, invitation:Invitation, googleUser?:{ [key: string]: any;} ) {
  const { uploadImage } = useUploadThumbnailImage();
  const [registerUserState, setRegisterSubmitState] = useState<RegisterUserState>({
    data: null,
    error: null,
    isLoading: false
  });

  const handleSubmit = async ( companyName: string, file: File, industry: string, country:string, address: string, contactEmail: string) => {
    setRegisterSubmitState({
      data: null,
      error: null,
      isLoading: true,
    });
    let logoURL = null;
    if (invitation.companyId === null && file && file.name){
      try {
        const uploadResponse = await uploadImage(file.name, file, companyName, invitation.token, invitation.email);
        if (!uploadResponse.thumbnailURL) {
          const uploadErrorState = {
            data: null,
            error: { message: 'Error uploading logo' },
            isLoading: false,
          };
          setRegisterSubmitState(uploadErrorState);
          return uploadErrorState;
        }
        logoURL = uploadResponse.thumbnailURL;
      } catch {
        const uploadErrorState = {
          data: null,
          error: { message: 'Error uploading logo' },
          isLoading: false,
        };
        setRegisterSubmitState(uploadErrorState);
        return uploadErrorState;
      }
    }
    try {
      const registerResponse = await registerUser(
        {
          token: invitation.token,
          name: userInfo.name,
          roleId: invitation.roleId,
          jobTitle: userInfo.jobTitle,
          email: invitation.email,
          password: userInfo.password,
          googleAccessToken: googleUser?.accessToken,
          campaignId: invitation.campaignId!,
          organizationName: companyName,
          companyId: invitation.companyId!,
          industry: industry,
          logoUrl: logoURL!,
          country: country,
          address: address,
          contactEmail: contactEmail
        });
      if (!registerResponse.data) {
        const registerError = {
          data: null,
          error: registerResponse.error,
          isLoading: false,
        };
        setRegisterSubmitState(registerError);
        return registerError;
      }
      const registerComplete = {
        data: registerResponse.data,
        error: null,
        isLoading: false,
      };
      setRegisterSubmitState(registerComplete);
      return registerComplete;
    } catch (e) {
      const errorState = {
        data: null,
        error: e,
        isLoading: false,
      };
      setRegisterSubmitState(errorState);
      return errorState;
    }
  };

  return { handleSubmit, registerUserState };
}
