import React from 'react';
import {
  Box,
  Grid,
  Button,
  TextInput,
  Link,
  useMediaQuery,
  H6,
  useTheme,
  makeStyles
} from '@adludio/components';
import { getURLQueryParams } from '../../util/redirect';
import GoogleCard from '../../Components/Reusables/GoogleCard';
import MainCard from '../../Components/Reusables/MainCard';
import { login } from '../../data';
import isEmailValid from '../../validator/isEmailValid';
import { AdludioTheme } from '@adludio/components/dist/Theme/types';
import handleRedirect from '../../util/redirect';
import { RouteComponentProps, Link as ReachLink } from '@reach/router';
import { AdludioLogo } from '../../Components/Reusables';
import { useSnackbar } from 'notistack';


const env = process.env.REACT_APP_BUILD_ENV ?? 'development';
const envPrefix =
  env === 'development' ? 'dev.' : env === 'production' ? '' : env + '.';

const apiSSOUrl = `//${envPrefix}api.sso.adludio.com`;

interface Error {
  status?: number,
  message?: string,
  label?: string
}

interface StateProps {
  message: string,
  isLoading: boolean,
  error: { [key: string]: Error | null },
  email: string,
  name: string,
  password: string,
  emailIsValid: boolean,
}

const initialState: StateProps = {
  message: '',
  emailIsValid: true,
  isLoading: false,
  error: {},
  email: '',
  name: '',
  password: ''

};

const useStyles = makeStyles((adludioTheme: AdludioTheme) => ({

  forgotPassword: {
    fontSize: 12
  }

}));

const Login = (props: RouteComponentProps<{ path: string }>) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const [state, setState] = React.useState(initialState);
  const { enqueueSnackbar } = useSnackbar();

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setState({ ...state, [e.target.name]: e.target.value });
  }

  const setStateFromServerResponse = (loginData: any) => {
    setState({ ...state, isLoading: true });
    const serverError = loginData.error;
    const serverSuccess = loginData.data;
    if (serverSuccess) {
      const { accessToken } = serverSuccess;
      handleRedirect(accessToken);
    } else {
      enqueueSnackbar(serverError.message);
    }
    setState({ ...state, isLoading: false });
  };

  async function handleSubmit(e: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (!isEmailValid(state.email)) {
      setState({ ...state, emailIsValid: false, isLoading: false });
    } else {
      setState({ ...state, emailIsValid: true, isLoading: true });
      const { email, password } = state;
      const loginResponse = await login(email, password);
      const loginData = await loginResponse.json();
      setStateFromServerResponse(loginData);
    }
  }

  const responseGoogle = async ({ email, accessToken }:{email:string, accessToken:string}) => {
    const loginResponse = await fetch(`${apiSSOUrl}/auth/google-user/login`, {
      method: 'POST',
      body: JSON.stringify({
        email: email,
        googleAccessToken: accessToken
      }),
      headers: {
        'content-type': 'application/json'
      }
    });
    const loginData = await loginResponse.json();
    setStateFromServerResponse(loginData);
  };


  const failGoogle = () => {
    setState({ ...state, error: { google: { message: 'Login with google failed - please sign up normally' } } });
  };

  const { email, password } = state;
  const { forgotPassword } = useStyles();
  return (
    <Box py={isDesktop && '5%'}>
      <Grid container direction='column' justify='center' alignItems='center'>
        <MainCard secondary>
          <Grid container direction='column' justify='center' alignItems='center'>
            <Grid item container direction='column' justify='center'>
              <Grid item>
                <Box pb='30%' pt='5%'>
                  <AdludioLogo />
                </Box>
              </Grid>
              <Box pb='5%'>
                <H6>Log into your Adludio account</H6>
              </Box>
            </Grid>
            <Grid
              xs={10}
              sm={8}
              md={10}
              container
              item
              direction='column'
              justify='center'
            >
              <Grid item xs>
                <Box pb='15%'>
                  <TextInput
                    label='Email'
                    required
                    variant='outlined'
                    value={email}
                    disabled={state.isLoading}
                    onChange={handleChange}
                    helperText={!state.emailIsValid ? 'Please enter a valid email address' : ''}
                    name='email'
                    error={!state.emailIsValid}
                  />
                </Box>
              </Grid>
              <Grid item xs>
                <TextInput
                  disabled={state.isLoading}
                  label='Password'
                  required
                  variant='outlined'
                  password
                  value={password}
                  onChange={handleChange}
                  name='password'
                />
              </Grid>
              <Grid container justify='flex-end' >
                <Box>
                  <Grid item >
                    <Link component={ReachLink} className={forgotPassword} to={`/forgot-password?${getURLQueryParams().raw}`}>
                      Forgot password?
                    </Link>
                  </Grid>
                </Box>
              </Grid>
              <Grid >
                <Box pt='5%' pb='80%'>
                  <Button fullWidth disabled={!state.email || !state.password} isLoading={state.isLoading} variant='contained' size='large' onClick={handleSubmit}>
                    {state.isLoading ? 'Loading...' : 'Log in'}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          {!isDesktop && <GoogleCard onFailure={failGoogle} onSuccess={responseGoogle} isLogin />}
        </MainCard>
        {isDesktop && (
          <>
            <Box padding={1} />
            <GoogleCard isLarge onFailure={failGoogle} onSuccess={responseGoogle} isLogin />
          </>
        )}
      </Grid>
    </Box>
  );
};

export default Login;
