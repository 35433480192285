import React, { useEffect } from 'react';
import { Grid, Typography, IconButton, Box, Link } from '@adludio/components';
import RemoveIcon from '@material-ui/icons/Delete';

interface FilePreviewProps {
  file: File | null;
  setFile: (file: File | null) => void;
}

export function FilePreview(props: FilePreviewProps) {
  const [fileUrl, setFileUrl] = React.useState<string>('');

  const removeFile = () => {
    URL.revokeObjectURL(fileUrl);
    props.setFile(null);
  };

  useEffect(() => {
    if (props.file) {
      const newUrl = URL.createObjectURL(props.file);
      setFileUrl(newUrl);
    } else {
      setFileUrl('');
    }
  }, [props.file]);

  const imgStyle: React.CSSProperties = { borderRadius: '10px', width: '100%', height: '100%', objectFit: 'cover' };

  return (
    <Box pt='1rem'>
      <Grid direction='row' container item>
        <Grid xs={2}> {props.file && <img src={fileUrl} alt={props.file.name} style={imgStyle} />}</Grid>
        <Grid container item xs={10} justify='flex-end' alignItems='center'>
          <Link onClick={removeFile} underline='none'>
            <Typography color='primary'>Remove </Typography>
          </Link>
          <IconButton onClick={removeFile}>
            <RemoveIcon color='primary' />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
}
