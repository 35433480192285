import React from 'react';
import { RouteComponentProps } from '@reach/router';
import useSSOGet from '../../util/hooks/useSSOGet';
import StatusCard from '../../Components/Reusables/StatusCard';
import ConfigContext from '../../store';
import { Signup } from './SignupOrchestor';
import { config } from '../../index';

export type Invitation = {
    email: string;
    companyName: string;
    roleId: number;
    token: string;
    expiresAt: Date;
    industry: string;
    country?:string;
    contactEmail?:string;
    address?:string;
    logoUrl: string;
    companyId?: number | null;
    campaignId?: string;
};


const SignUpContainer = ({ invitationToken }: RouteComponentProps<{ invitationToken: string }>) => {


  const {
    config: { ssoBEURL },
  } = React.useContext(ConfigContext);
  let invitation = useSSOGet<Invitation>(ssoBEURL, `emails/invitations/${invitationToken}`);


  if (!invitation) return null;

  return invitation.data ? (
    <Signup
      successURL={config.portalFEURL}
      invitation={invitation.data}
    />
  ) : (
    <StatusCard errorLabel={'whatever'} />
  );
};

export default SignUpContainer;
