import React, { useCallback, useEffect, useState } from 'react';
import { ValidationFunc, ValidationResult } from './validators';


export function useFormField<T>(initValue: T, validators: ValidationFunc<T>[] = []) {
  const [value, setValue] = useState<T>(initValue);
  const [errState, setErrState] = useState<{ message: string, isValid: boolean } | null>(null);

  const validate = useCallback((val: T) => {
    let validResult: ValidationResult = { isValid: validators.length === 0, message: '' };
    for (const validator of validators) {
      validResult = validator(val);
      // There is no need to keep testing if one validator already mark the field as invalid
      if (!validResult.isValid) {
        break;
      }
    }
    return validResult;
  }, [validators]);

  const updateValue = useCallback((newValue: T) => {
    setErrState(validate(newValue));
    setValue(newValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e: React.ChangeEvent<any>) => {
    setErrState(validate(e.target.value));
    setValue(e.target.value);
  };

  const clear = () => {
    setErrState(null);
    setValue(initValue);
  };

  const triggerValidation = (val:T)=>{
    setErrState(validate(val));
  };

  useEffect(() => {
    if (initValue) {
      setErrState(validate(value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    value,
    onChange,
    updateValue,
    clear,
    isValid: errState?.isValid,
    message: errState?.message,
    triggerValidation
  };
}
