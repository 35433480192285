import React from 'react';

import LoginCard from './LoginCard';
import { Grid } from '@adludio/components';
import { makeStyles } from '@material-ui/core';
import { AdludioTheme } from '@adludio/components/dist/Theme/types';
import { RouteComponentProps} from '@reach/router';
import adludioRebrandLogo from '../../static/adludio_logo_white.png';
import { Box } from '@adludio/components';
import { H2 } from '@adludio/components';
import { H6 } from '@adludio/components';

const useStyles = makeStyles<AdludioTheme>((theme) => ({
  root: {
    minWidth: '100vw',
    minHeight: '100vh',
    backgroundColor: theme.palette.primary.dark,
    boxSizing: 'border-box',
  },
  topText: {
    fontWeight: 700,
    color: '#76FA5A',
  },
  bottomText: {
    fontWeight: 700,
    color: '#C2FCB4',
  },
  subtitle: {
    color: theme.palette.primary.contrastText,
  },
  logo: {
    height: '2rem',
    left: 0,
  }
}));

// Simplified because only one env

const Login = (props: RouteComponentProps<{ path: string }>) => {
  const { topText, bottomText, root, subtitle, logo } = useStyles();

  return (
    <Grid container direction='row' className={root}>
      <Grid container item direction='column' xs={6} alignItems='end'>
        <Grid xs={1}>
          <Box pt='7rem' pl='7rem'>
            <img src={adludioRebrandLogo} className={logo} alt='logo'/>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Box width='30rem' height='10rem' pt='3rem' pl='7rem'>
            <H2 variant='h2' className={topText}>
              The Premium <br /> Platform
            </H2>
            <H2 variant='h2' className={bottomText}>
              To Win Attention
            </H2>
            <Box pt='3rem'>
              <H6 variant='h6' className={subtitle}>
                All the mobile ad products you need, powered by AI, in one easy platform.
              </H6>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={5}>
        <Box pt='4rem'>
          <LoginCard />
        </Box>
      </Grid>
    </Grid>
  );
};

export default Login;
