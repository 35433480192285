import React from 'react';

import { Grid, Caption2, H6, Box, makeStyles } from '@adludio/components';
import MainCard from '../MainCard';

import * as emojis from '../../../static/emoji';

import invitationErrors from './invitationErrors';

// Because typescript complains otherwise 😡😡😡
const emojiMap: {[K in keyof (typeof emojis)]: typeof emojis[K]} = { ...emojis };

const statusCardStyles = makeStyles(() => ({
  container: {
    minHeight: 600
  }
}));

const StatusCard = ({ errorLabel }: { errorLabel: keyof typeof invitationErrors }) => (
  <MainCard>
    <Grid container direction='column' alignItems='center' justify='center' item className={statusCardStyles().container}>
      <Grid container item direction='column' alignItems='center' justify='center'>
        <Grid item xs md={10}>
          <H6>
              Oops! <br /> {invitationErrors[errorLabel].header}
          </H6>
        </Grid>
        <Grid item xs md={6}>
          <Box pt={3} pb={3}>
            <Caption2>{invitationErrors[errorLabel].body}</Caption2>
          </Box>
        </Grid>
        <Grid item xs>
          <img
            src={emojiMap[invitationErrors[errorLabel].emoji]}
            height={32}
            alt={`${invitationErrors[errorLabel].emoji} emoji`}
          />
        </Grid>
      </Grid>
    </Grid>
  </MainCard>
);

export default StatusCard;
