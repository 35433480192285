import React from 'react';
import { SSOConfig, } from '../';

const ConfigContext = React.createContext<{
  config: SSOConfig;
}>({
  config: {
    env: '',
    ssoBEURL: '',
    envPrefix: '',
    portalFEURL: ''
  }
});


export enum Props {
  name = 'name',
  email = 'email',
  error = 'error',
  password = 'password',
}

export default ConfigContext;







