const env = process.env.REACT_APP_BUILD_ENV ?? 'development';
const envPrefix = env === 'development' ? 'dev.' : env === 'production' ? '' : env + '.';

export function getThumbnailSignedURL(data:{fileName:string, companyName:string, token:string, email:string}) {
  return fetch(`//${envPrefix}api.sso.adludio.com/auth/getMagicLink`, {
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .catch((err) => console.log(err));
}

export function uploadImageToBucket(imageUrl: string, data: any) {
  return fetch(imageUrl, {
    method: 'PUT',
    body: data,
  })
    .then((res) => {
      return true;
    })
    .catch((err) => console.log(err));
}
