/* eslint-disable complexity */
import { FIELD_VALIDATORS, useFormField } from '../../../Components/FormField';
import { useState } from 'react';
import { OrganisationDetails } from './OrganisationSignup';

export interface OrganisationFormInitialState {
    companyName?: string;
    industry?: string;
    country?:string;
    contactEmail?:string;
    address?:string;
}

export function useOrganizationForm(initialValues: OrganisationFormInitialState, storedOrganizationDetails?:OrganisationDetails) {
  const [companyLogo, setCompanyLogo] = useState<File | null>(storedOrganizationDetails?.companyLogo ?? null);
  const industryField = useFormField(initialValues.industry || storedOrganizationDetails?.industry || '', [FIELD_VALIDATORS.isRequired]);
  const companyNameField = useFormField(initialValues.companyName || storedOrganizationDetails?.companyName || '', [FIELD_VALIDATORS.isRequired]);
  const legalField = useFormField( storedOrganizationDetails?.legal, []);
  const hotjarField = useFormField(storedOrganizationDetails?.hotjar, []);
  const countryField = useFormField(initialValues.country || storedOrganizationDetails?.country || '', [FIELD_VALIDATORS.isRequired]);
  const addressField = useFormField(initialValues.address || storedOrganizationDetails?.address || '', [FIELD_VALIDATORS.isRequired]);
  const emailContactField = useFormField(initialValues.contactEmail || storedOrganizationDetails?.contactEmail || '', [FIELD_VALIDATORS.isRequired]);


  const isFormValid = () => {
    return industryField.isValid && legalField.value && companyNameField.isValid && addressField.isValid && emailContactField.isValid && countryField.value ;
  };

  return {
    setCompanyLogo,
    industryField,
    companyLogo,
    legalField,
    hotjarField,
    companyNameField,
    countryField,
    addressField,
    emailContactField,
    isFormValid,
  };
}
