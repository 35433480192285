import {
  Body2,
  Box,
  Button,
  Grid,
  H5,
  TextInput,
  useMediaQuery,
  useTheme,
} from '@adludio/components';
import { FormControl, FormHelperText } from '@material-ui/core';

import { AdludioLogo } from '../../Components/Reusables';
import { AdludioTheme } from '@adludio/components/dist/Theme/types';
import MainCard from '../../Components/Reusables/MainCard';
import { NewPwSuccess } from '../../Components/Cards';
/* eslint-disable complexity */
import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { getURLQueryParams } from '../../util/redirect';
import isEmailValid from '../../validator/isEmailValid';
import isPasswordValid from '../../validator/isPasswordValid';
import { makeStyles } from '@adludio/components';
import { resetPassword } from '../../data';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme: AdludioTheme) => ({
  helperError: {
    paddingInlineStart: '15px',
    color: theme.palette.error.main
  },
  passwordField: {
    width: '100%'
  }
}));

const NewPassword = ({ path }: RouteComponentProps<{ path: string }>) => {
  interface Error {
    status?: number,
    message?: string,
    label?: string
  }

  interface StateProps {
    message: string,
    isLoading: boolean,
    emailIsValid: boolean,
    hash: string,
    payload: string,
    passwordIsValid: boolean,
    name: string,
    password: string,
    email: string,
    error: { [key: string]: Error | null },
    submitSuccess: boolean
  }

  const initialState: StateProps = {
    message: '',
    isLoading: false,
    emailIsValid: true,
    passwordIsValid: true,
    hash: '',
    payload: '',
    name: '',
    password: '',
    email: '',
    error: {},
    submitSuccess: false
  };
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const [state, setState] = React.useState(initialState);
  const { enqueueSnackbar } = useSnackbar();
  const { helperError, passwordField } = useStyles();
  const { encoded } = getURLQueryParams();

  const hasCapitalLetter = (pass:string)=>{
    return new RegExp( /[A-Z]+/m).test(pass);
  };
  const hasSpecialCharacter = (pass:string)=>{
    return new RegExp( /(?:[@$!%*#?&]+)/).test(pass);
  };
  const hasNumber = (pass:string)=>{
    return new RegExp(/\d/).test(pass);
  };

  function handlePasswordChange(e: React.ChangeEvent<HTMLInputElement>) {
    setState({ ...state, password: e.target.value.trim() });
  }

  function handleEmailChange(e: React.ChangeEvent<HTMLInputElement>) {
    setState({ ...state, email: e.target.value.trim() });

  }

  async function handleSubmit(e: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement> | React.FormEvent<HTMLAnchorElement>) {
    e.preventDefault();

    const { payload, hash, password } = state;
    let passwordIsValid = false;
    let emailIsValid = false;
    let emailError = { email: { message: 'Please use a valid email address ' } };

    if (isPasswordValid(password)) {
      passwordIsValid = true;
    } else {
      setState({ ...state, passwordIsValid });
      return;
    }
    if (isEmailValid(state.email)) {
      emailIsValid = true;
      emailError = { email: { message: '' } };
      try {
        const passwordResetResponse = await resetPassword(password, hash, payload);
        const passwordResetData = await passwordResetResponse.json();
        const serverSuccess = passwordResetData.data;
        if (serverSuccess) {
          setState({ ...state, error: { server: null, ...emailError }, passwordIsValid, emailIsValid, submitSuccess: true });
        } else {
          setState({
            ...state,
            error: {
              ...emailError,
              server: {
                message: passwordResetData.error.message,
                label: passwordResetData.error.label,
                status: passwordResetData.error.status
              }
            },
            passwordIsValid, emailIsValid
          });
          enqueueSnackbar(passwordResetData.error.message, { variant: 'error' });
          return;
        }
      } catch (error){
        enqueueSnackbar('Something went wrong', { variant: 'error', autoHideDuration: 1000 });
        return;
      }
    } else {
      setState({ ...state, error: { ...emailError }, passwordIsValid, emailIsValid });
    }

  }

  React.useEffect(()=>{
    setState({ ...state, hash: encoded.hash as string, payload: encoded.payload as string, email: encoded.email as string });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const { email, password } = state;
  return (
    !state.submitSuccess ?
      (
        <Box py={isDesktop && '5%'} >
          <Grid container direction='column' justify='center' alignItems='center'>
            <MainCard secondary>
              <Grid container direction='column' justify='center' alignItems='center' >
                <Grid item container direction='column' justify='center'>
                  <Grid item>
                    <Box pt='5%' pb='30%'>
                      <AdludioLogo />
                    </Box>
                  </Grid>
                  <Grid >
                    <H5 >Create New Password</H5>
                    <Box pb='15%'>
                      <Body2 >{'Check your details before submitting.'}</Body2>
                    </Box>
                  </Grid>
                </Grid>
                <Grid
                  xs={10}
                  sm={8}
                  md={10}
                  container
                  item
                  direction='column'
                  justify='center'
                >
                  <Grid item xs>
                    <Box pb='15%'>
                      <TextInput
                        label='Email'
                        required
                        variant='outlined'
                        value={email}
                        disabled={state.isLoading}
                        onChange={handleEmailChange}
                        name='email'
                        helperText={!state.emailIsValid && 'Please a valid email address'}
                        error={!state.emailIsValid}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs>
                    <FormControl className={passwordField}>
                      <TextInput
                        disabled={state.isLoading}
                        label='Password'
                        required
                        variant='outlined'
                        password
                        value={password}
                        fullWidth
                        onChange={handlePasswordChange}
                        name='password'
                        error={!state.passwordIsValid && state.password !== ''}
                      />
                      <FormHelperText component='div'>
                        { !state.passwordIsValid ? (
                          <ul className={helperError} >
                            {(password.length < 8 || state.passwordIsValid) && (<li>Password should be at least 8 characters in length.&nbsp;</li>)}
                            { (!hasCapitalLetter(password) || state.passwordIsValid) && (<li>Must contain at least 1 uppercase characters.&nbsp;</li>)}
                            { (!hasSpecialCharacter(password) || state.passwordIsValid) && (<li>Must contain at least 1 special character (@$!%*#?&).&nbsp;</li>)}
                            { (!hasNumber(password) || state.passwordIsValid) && (<li>Must contain  at least 1 number.&nbsp;</li>)}
                          </ul>
                        ) : (
                          '  Password needs to contain 8 characters. Must include at least one number, one uppercase character and one special character(@$!%*#?&). '
                        )
                        }
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs>
                    <Box pt='15%' pb='100%'>
                      <Button fullWidth disabled={!state.email} isLoading={state.isLoading} variant='contained' size='large' onClick={handleSubmit}>
                        {state.isLoading ? 'Loading...' : 'Submit'}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </MainCard>
          </Grid>
        </Box>
      )
      :
      (
        <NewPwSuccess />
      )
  );
};


export default NewPassword;

